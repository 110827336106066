import React from 'react';

import logo from '../assets/img/ph_slack-logo-duotone.svg'
import telegram from '../assets/img/ic_outline-telegram.svg'

import { Link } from 'react-scroll';

import { useTranslation } from "react-i18next"; 

function Footer() {
  const { t } = useTranslation();
  return (
  <>
    <footer className='bg-[#212121] xl:h-[319px] md:h-[222px] hidden sm:block'>
      <div className='flex'>
        <img src={logo} alt="" className='xl:ml-[148px] md:ml-[40px] mt-[40px]'/>
        <div className='flex flex-col xl:mt-[65px] md:mt-[40px] xl:ml-[124px] md:ml-[40px]'>
          <Link to="about" spy={true} smooth={true} offset={-70} duration={500} className='font-sans text-[17px] text-[#ffffffb3] font-light'>{t('header_1')}</Link>
          <Link to="service" spy={true} smooth={true} offset={-70} duration={500} className='font-sans text-[17px] text-[#ffffffb3] font-light mt-[8px]'>{t('header_2')}</Link>
          <Link to="vacancy" spy={true} smooth={true} offset={-70} duration={500} className='font-sans text-[17px] text-[#ffffffb3] font-light mt-[8px]'>{t('header_3')}</Link>
          <Link to="rating" spy={true} smooth={true} offset={-70} duration={500} className='font-sans text-[17px] text-[#ffffffb3] font-light mt-[8px]'>{t('header_4')}</Link>
        </div>
        <div className='flex flex-col xl:mt-[65px] md:mt-[40px] xl:ml-[143px] md:ml-[94px]'>
          <p className='font-sans text-[17px] text-[#ffffffb3] font-light'>{t('69contact_information')}</p>
          <div className='mt-[10px]'>
            <p className='font-sans text-[17px] text-[#ffffffb3] font-light'>5 St John's Ln, London EC1M 4BH, Great Britain</p>
          </div>
          <div className='flex mt-[10px]'>
            <img src={telegram} alt="" />
            <a href="https://t.me/work_of_my_dream" target="_blank"><p className='font-sans text-[17px] text-[#ffffffb3] font-light ml-[5px]'>@work_of_my_dream</p></a>
          </div>
          <p className='font-sans text-[17px] text-[#ffffffb3] font-light mt-[80px]'>©2024 Global Industry Leadership. All Rights Reserved</p>
        </div>
        <div className='flex flex-col xl:mt-[65px] md:mt-[40px] xl:ml-[100px] md:ml-[94px]'>
            <a href="/GlobalIndustry _Terms.pdf" target="_blank"><p className='font-sans text-[17px] text-[#ffffffb3] font-medium'>Terms and Conditions</p></a>
        </div>
      </div>  
    </footer>
    <footer className='bg-[#212121] h-[440px] block sm:hidden'>
      <div className='flex flex-col'>
        <img src={logo} alt="" className='mt-[40px] ml-[18px] w-[50px] h-[50px]'/>
        <div className='flex flex-col ml-[18px] mt-[25px]'>
          <Link to="about" spy={true} smooth={true} offset={-1700} duration={500} className='font-sans text-[17px] text-[#ffffffb3] font-light'>{t('header_1')}</Link>
          <Link to="servicetablet" spy={true} smooth={true} offset={-600} duration={500} className='font-sans text-[17px] text-[#ffffffb3] font-light mt-[8px]'>{t('header_2')}</Link>
          <Link to="vacancy" spy={true} smooth={true} offset={-600} duration={500} className='font-sans text-[17px] text-[#ffffffb3] font-light mt-[8px]'>{t('header_3')}</Link>
          <Link to="rating" spy={true} smooth={true} offset={-70} duration={500} className='font-sans text-[17px] text-[#ffffffb3] font-light mt-[8px]'>{t('header_4')}</Link>
        </div>        
      </div>
      <div className='flex flex-col mt-[14px] ml-[18px]'>
          <p className='font-sans text-[17px] text-[#ffffffb3] font-light'>{t('69contact_information')}</p>
          <p className='font-sans text-[17px] text-[#ffffffb3] font-light mt-[10px]'>5 St John's Ln, London EC1M 4BH, Great Britain</p>
          <div className='flex mt-[10px]'>
            <img src={telegram} alt="" />
            <a href="https://t.me/work_of_my_dream" target="_blank"><p className='font-sans text-[17px] text-[#ffffffb3] font-light ml-[5px]'>@work_of_my_dream</p></a>
          </div>
          <a href="/GlobalIndustry _Terms.pdf" target="_blank"><p className='mt-[10px] font-sans text-[17px] text-[#ffffffb3] font-medium'>Terms and Conditions</p></a>
          <p className='font-sans text-[17px] text-[#ffffffb3] font-light mt-[14px]'>©2024 Global Industry Leadership. All Rights Reserved</p>
        </div>
    </footer>

  </>
  
);}

export default Footer;
