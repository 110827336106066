import React from 'react';

import one from '../../assets/img/agency_img4.jpg.png'
import two from '../../assets/img/agency_img5.jpg.png'
import three from '../../assets/img/agency-element.png.png'
import four from '../../assets/img/sec.png'
import five from '../../assets/img/agency_img1.jpg.png'
import six from '../../assets/img/agency_img2.jpg.png'
import seven from '../../assets/img/agency-rocket.png.png'
import eight from '../../assets/img/4.png'
import nine from '../../assets/img/5.png'
import mobileabout from '../../assets/img/mobileabout.png'

import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

import { useTranslation } from "react-i18next";

import Header from '../Header'

function About() {

  const { t } = useTranslation();
  return (
    <>
    <div className='container flex mx-auto flex-col-reverse xl:flex-row' id="about">
        <div className='flex mt-[40px] sm:mt-[0px]'>
            <Fade bottom><img src={one} alt="" className='sm:w-[413.791px] w-[204.038px] h-[176.966px] sm:h-[358.889px] mt-[26.02px]'/></Fade>
            <Fade bottom><img src={two} alt="" className='sm:w-[264.894px] w-[150.107px] sm:h-[219.677px] h-[124.484px] drop-shadow-2xl'/> </Fade>
        </div>
        <div className='w-[339px] sm:w-[525px] xl:ml-[170px] md:ml-[50px] xl:pb-[0px] md:pb-[40px] ml-[20px]' >
            <div className='flex'>
                <h1 className='text-[25px] mt-[90px] sm:mt-[0px] sm:text-[50px] rubik-font font-bold '>{t('05who_we_are')}</h1>
                <img src={three} alt="" className='ml-[33px] hidden sm:flex'/>
            </div>
            <div className='mt-[21px]'>
                <p className='rubik-font text-[#212121] text-[14px] sm:text-[16px] font-light'>{t('02six_years_working')}</p>
                <p className='rubik-font text-[#212121] text-[14px] sm:text-[16px] mt-[20px] font-light'>{t('03expert_in_segment')}</p>
                <p className='rubik-font text-[#212121] text-[14px] sm:text-[16px] mt-[20px] font-light'>{t('04clients_trust_us')}</p>
            </div>
        </div>
    </div>
    <div className='container flex mx-auto flex-col xl:flex-row xl:mt-[140px] md:mt-[60px]'>
        <div className='w-[339px] sm:w-[540px] xl:ml-[170px] md:ml-[50px] xl:pb-[0px] md:pb-[40px] ml-[20px] mt-[60px]'>
                <div className='flex'>
                    <h1 className='text-[25px] w-[216px] sm:w-full mt-[20px] sm:mt-[0px] sm:text-[50px] rubik-font font-bold'>{t('06global_industry_leadership')}</h1>
                </div>
                <div className='mt-[21px] sm:mt-[35px]'>
                    <p className='rubik-font text-[#212121] text-[14px] sm:text-[16px] font-light'>{t('07market_analysis')}</p>
                </div>
        </div>
        {/* Mobile Block */}
        <div className='sm:hidden'>
            <Fade bottom><img src={mobileabout} alt="" className='pl-[20px] mt-[20px]'/></Fade>

        </div>
        {/* Mobile Block */}      
        <div className='ml-[170px] hidden sm:block'>
            <div className='flex'>
                <Fade bottom><img src={five} alt="" /></Fade>
                <Zoom><img src={seven} alt="" className='w-[119px] h-[167.44px] ml-[54.5px]'/></Zoom>
            </div>
            <div className='flex'>
                <img src={four} className='w-[154.5px] h-[185.712px] mt-[67.35px]' alt="" />
                <Fade bottom><img src={six} alt="" className='absolute ml-[199.89px] mt-[-69px]'/></Fade>
            </div>
        </div>   
    </div>
    <div className='container flex mx-auto mt-[140px] hidden sm:hidden xl:flex' id="service">
        <div className='ml-[170px]'>
            <h1 className='text-[50px] rubik-font text-[#212121] font-bold'>{t('08our_services')}</h1>
            <p className='mt-[60px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('09marketing_research')}</p>
            <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('10market_analysis')}</p>
            <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('11analytical_market_research')}</p>
            <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('12market_analytical_review')}</p>
            <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('13competitor_analysis')}</p>
            <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('14market_passport')}</p>
            <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('15production_analysis')}</p>
            <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('16sales_analysis_of_goods_and_services')}</p>
        </div>
        <div className='ml-[354px]'>
            <p className='mt-[135px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('17external_trade_analysis')}</p>
            <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('18market_research_for_legal_companies')}</p>
            <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('19market_research_and_strategy_for_business_idea')}</p>
            <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('20price_and_assortment_monitoring_in_stores')}</p>
            <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('21business_database')}</p>
            <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('22market_analysis_during_crisis')}</p>
            <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('23financial_consulting')}</p>
        </div>
    </div>
     {/* Mobile,Tablet Block */}    
    <div className='container flex mx-auto sm:mt-[140px] mt-[60px] xl:hidden block' id="servicetablet">
        <div className='xl:ml-[170px] md:ml-[50px] ml-[20px]'>
            <h1 className='text-[25px] sm:mt-[0px] sm:text-[50px] rubik-font text-[#212121] font-bold'>{t('08our_services')}</h1>
                <p className='mt-[30px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('09marketing_research')}</p>
                <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('10market_analysis')}</p>
                <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('11analytical_market_research')}</p>
                <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('12market_analytical_review')}</p>
                <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('13competitor_analysis')}</p>
                <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('14market_passport')}</p>
                <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('15production_analysis')}</p>
                <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('16sales_analysis_of_goods_and_services')}</p>
                <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('17external_trade_analysis')}</p>
                <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('18market_research_for_legal_companies')}</p>
                <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('19market_research_and_strategy_for_business_idea')}</p>
                <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('20price_and_assortment_monitoring_in_stores')}</p>
                <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('21business_database')}</p>
                <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('22market_analysis_during_crisis')}</p>
                <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('23financial_consulting')}</p>
        </div>
    </div>
     {/* Mobile,Tablet Block */}    
    <div className='container flex mx-auto mt-[60px] sm:mt-[140px] flex-col xl:flex-row'>
        <div className='w-[339px] sm:w-[540px] xl:ml-[170px] md:ml-[50px] ml-[20px]'>
            <div className='flex'>
                <h1 className='text-[25px] mt-[20px] sm:mt-[0px] sm:text-[50px] text-[#212121] rubik-font font-bold '>{t('24business_planning')}</h1>
            </div>
            <div className='mt-[35px]'>
                <p className='mt-[0px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('25business_plan_development_international_standards')}</p>
                <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('26business_plan_for_start-up_projects')}</p>
                <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('27investment_project_and_investment_proposal')}</p>
                <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('28financial_model_development_business_constructor')}</p>
                <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('29from_business_idea_to_successful_business')}</p>
                <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('30preparation_of_presentation_business_plan_for_investor')}</p>
                <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('31development_of_investment_proposal')}</p>
                <p className='mt-[15px] text-[#212121] text-[14px] sm:text-[16px] font-light leading-[30px] rubik-font'>{t('32business_evaluation')}</p>
            </div>
        </div>    
        <div className='xl:ml-[170px] md:ml-[100px] flex'>
            <Fade bottom><img src={eight} alt="" className='w-[268.431px] h-[253.326px] sm:w-[495.352px] sm:h-[467.478px] ml-[100px] sm:ml-[200px] mt-[56px]'/></Fade>
            <Fade bottom><img src={nine} alt="" className='sm:w-[297.872px] w-[168.921px] sm:h-[266.908px] h-[151.362px] absolute mt-[80px] sm:mt-[100px] rounded-tl-2xl rounded-br-2xl'/></Fade>
        </div>   
    </div>
    </>

  );
}

export default About;