import React from 'react';
import Main from '../components/Home/Main';
import Ticker from '../components/Home/Ticker';
import About from '../components/Home/About';
import Vacancy from '../components/Home/Vacancy';
import Rating from '../components/Home/Rating';


import Header from '../components/Header'


function Home() {
  return (
    <div className='bg-[#F4ECE5]'>
      <Main/>
      <Ticker/>
      <About/>
      <div id="vacancy">
        <Vacancy/>
      </div>
      <div id="rating">
        <Rating/>
      </div>
    </div>
  );
}

export default Home;