import React from 'react';
import { useState, useEffect } from "react";

import Fade from 'react-reveal/Fade';

import girl from '../../assets/img/girl.png'
import girl2 from '../../assets/img/Img.png'
import girl3 from '../../assets/img/girl3.png'
import lamp from '../../assets/img/agency-lamp.png.png'

import $ from 'jquery';

import Form from '../../components/Form/Form';

import { useTranslation } from "react-i18next";

import Header from '../../components/Header';

function Main() {
  const { t } = useTranslation();
  const [ formOpened, setFormOpened ] = useState(false);
  useEffect(() => {
    if (formOpened) {
        $('body').addClass("ovf-hidden");
    } else {
        $('body').removeClass("ovf-hidden");
    }
}, [ formOpened ]);
  return (
    <>
    <Form formOpened={formOpened} setFormOpened={setFormOpened} />
    <Header />
    <div className='flex justify-center flex-col sm:flex-row'>
        <div className='mt-[25px] sm:mt-[136px] sm:ml-[20px] ml-[]'>
            <p className='flex mx-auto text-[#212121] xl:text-[85px] sm:text-[60px] text-[35px] xl:w-[486px] md:w-[339px] w-[234px] text-center sm:text-left font-bold leading-[116%] rubik-font'>Global Industry Leadership</p>
            <button onClick={() => setFormOpened(true)} className='bg-[#CF2D10] rounded-[30px] py-[10px] px-[35px] mt-[20px] sm:mt-[60px] flex sm:inline-block mx-auto'>
                <p className='text-white text-[17px] font-normal'>{t('main_button')}</p>
            </button>
            <img src={lamp} alt="" className='mt-[90px] ml-[60px] hidden md:block xl:hidden'/>
        </div>
        <div>
          <Fade duration={1500}><img src={girl} alt="" className='mt-[12px] hidden xl:block hidden'/></Fade>
          <Fade duration={1500}><img src={girl2} alt="" className='mt-[60px] xl:hidden md:block hidden'/></Fade>
          <Fade duration={1500}><img src={girl3} alt="" className='mt-[22px] sm:hidden mx-auto'/></Fade>
        </div>
    </div>
    </>
    
  );
}

export default Main;