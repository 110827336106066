import React from 'react';
import Marquee from "react-fast-marquee";

import { useTranslation } from "react-i18next"; 

function Ticker() {
  const { t } = useTranslation();
  return (
    <div className='bg-[#212121] xl:h-[76px] sm:h-[70px] xl:mb-[60px] sm:mb-[20px]'>
      <Marquee autoFill>
        <div className='sm:mt-[8px] flex py-[10px]'>
          <h1 className='xl:text-[30px] sm:text-[20px] text-[14px] italic font-normal text-[#F4ECE5] leading-[140%] mr-[20px]'>{t('70success')}</h1>
          <h1 className='xl:text-[30px] sm:text-[20px] text-[14px] italic font-normal text-[#F9816B] leading-[140%] mr-[20px]'>{t('71innovations')}</h1>
          <h1 className='xl:text-[30px] sm:text-[20px] text-[14px] italic font-normal text-[#F4ECE5] leading-[140%] mr-[20px]'>{t('72infinite_resource')}</h1>
          <h1 className='xl:text-[30px] sm:text-[20px] text-[14px] italic font-normal text-[#F9816B] leading-[140%] mr-[20px]'>{t('70success')}</h1>
          <h1 className='xl:text-[30px] sm:text-[20px] text-[14px] italic font-normal text-[#F4ECE5] leading-[140%] mr-[20px]'>{t('71innovations')}</h1>
          <h1 className='xl:text-[30px] sm:text-[20px] text-[14px] italic font-normal text-[#F9816B] leading-[140%] mr-[20px]'>{t('72infinite_resource')}</h1>
        </div>     
      </Marquee>   
    </div>
  );
}
export default Ticker;